<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="supplier_lst"
      :items-per-page="10"
      item-key="id"
      sort-by="name"
      class="elevation-4"
      :loading="loading_status"
      loader-height="10"
      loading-text="Cargando ..."
    >
      <template #top>
        <v-toolbar flat>
          <h1>Proveedores</h1>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-magnify
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";


export default {
  components: {},
  data() {
    return {
      errors: [],
      valid: false,
      loading_status: false,
      f_required: [(v) => !!v || "Requerido"],
      f_email: [
        (v) => !!v || "Correo valido",
        (v) => /.+@.+/.test(v) || "Correo no valido",
      ],
      imageUrl: null,
      dialog: false,
      headers: [
        {
          text: "Codigo",
          align: "start",
          sortable: true,
          value: "codigo",
          dataType: "text",
        },
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "nombre",
          dataType: "text",
        },
        {
          text: "Compañía",
          align: "start",
          sortable: true,
          value: "compania",
          dataType: "text",
        },
      
      ],
      supplier_lst: [],
    };
  },
  mounted() {
    this.get_supplier();
  },
  methods: {
    get_supplier() {
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        table: "proveedores",
      };
      qry.filters = [
        {
          field: "store",
          operator: "=",
          value: window.store.store_owner,
        },
      ];

      webserver("get_table", qry, (data) => {

        this.loading_status = false;
        this.supplier_lst = data;
      });
    },
  },
};
</script>

<style></style>
